.containerDataGrid {
  height: calc(100vh - 200px);
  width: 100%;
  box-shadow: 3px;
  border-radius: 2px;
  overflow: hidden;
}
.titleForm {
  border-bottom: 2px solid #1976d2;
  padding-bottom: 0.5px;
  margin-bottom: 1.5px;
}
.iconsDatagrid {
  height: 35px;
  width: 35px;
}
.cardStaff {
  position: relative;
  width: 295px;
  height: 380px;
  // background: #000;
  border-radius: 18px;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

  .posterStaff {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::before {
      content: "";
      position: absolute;
      bottom: -45%;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      transition: 0.3s;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
      transition: 0.3s;
    }
  }

  &:hover {
    .posterStaff {
      &::before {
        bottom: 0;
      }

      img {
        transform: scale(1.1);
      }
    }

    .details {
      bottom: 0;
    }
  }

  .details {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    height: auto;
    padding: 1.5em 1.5em 2em;
    background: rgba(223, 221, 221, 0.651);
    backdrop-filter: blur(16px) saturate(120%);
    transition: 0.2s;
    z-index: 2;

    h1,
    h2 {
      font-weight: 700;
    }

    h1 {
      font-size: 1em;
      margin-bottom: 5px;
    }
  }
}
.list-item {
  color: #fff;
  display: flex;
  align-items: center;
  padding: 12px 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  &:hover {
    background-color: #2c3e50;
  }
}
.vertical-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 22px;
  width: 2px;
  background-color: #666;
  border-radius: 2px;
}
.list-option {
  color: #fff;
  padding-left: 5rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  position: relative;
  transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
  background-color: transparent;
  &:hover {
    transform: translateX(4px);
  }

  &.active {
    color: #ff5050;
    background-color: #2a3b4d;
    &::before {
      width: 8px;
      height: 8px;
    }
  }
}
.img-user {
  max-width: 15%;
  height: auto;
  object-fit: cover;
  border-radius: 50%;
}
.modalContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 90%;
  border: 1px solid #006ab1;
  box-shadow: 24px;
  padding: 1rem;
  @media (min-width: 600px) {
    width: 70%; /* Para pantallas sm */
  }

  @media (min-width: 960px) {
    width: 600px; /* Para pantallas md */
  }
}
